<template>
    <auth-layout :title="$t('auth.password.reset.title')">
        <input type="hidden" value="form.token" />
        
        <v-form-group :label="$t('label.new_password')" class="mb-4" :v="v$.form.password">
            <v-form-input v-model.trim="form.password" type="password" autocomple="new_password" />
        </v-form-group>

        <v-form-group :label="$t('label.new_password_confirmation')" :v="v$.form.password_confirmation">
            <v-form-input v-model.trim="form.password_confirmation" type="password" autocomplete="new_password" />
        </v-form-group>
        
        <template #footer>
            <v-button type="submit" variant="primary" class="w-full" :loading="loading" @click="handleChange">
                {{ $t('action.save') }}
            </v-button>
        </template>
    </auth-layout>
</template>

<script>
import { mapActions } from 'vuex';

import { useVuelidate } from '@vuelidate/core';
import { required, minLength, sameAs } from '@vuelidate/validators';

export default {
    setup () {
        return { v$: useVuelidate() }
    },

    data: () => ({
        loading: false,
        form: {
            token: null,
            password: null,
            password_confirmation: null
        }
    }),

    mounted () {
        this.form.token = new URL(document.location).searchParams.get('reset_password_token');
    },
    
    validations () {
        return {
            form: {
                password: { required, minLength: minLength(8) },
                password_confirmation: { sameAsPassword: sameAs(this.form.password) }
            }
        }
    },

    methods: {		
        ...mapActions('auth', [
            'resetPassword'
		]),

        handleChange (event) {
            event.preventDefault();

			this.v$.form.$touch();

            if (this.v$.form.$invalid) return;

            this.loading = true;

			this.resetPassword(this.form).finally(() => {
                this.loading = false;
            }).then(() => {
                this.$redirect('login');
            });
		},
    }
};
</script>
